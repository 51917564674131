import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Card from '../components/card'
import FeatureCard3 from '../components/feature-card3'
import Footer from '../components/footer'
import './accueuil.css'

const Accueuil = (props) => {
  return (
    <div className="accueuil-container">
      <Helmet>
        <title>Sandrine PERRIERE Sophrologie</title>
        <meta
          name="description"
          content="Sandrine PERRIERE Sophrologie et Gestalt-thérapie"
        />
        <meta property="og:title" content="Sandrine PERRIERE Sophrologie" />
        <meta
          property="og:description"
          content="Sandrine PERRIERE Sophrologie et Gestalt-thérapie"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/86f61eb3-3acf-4fd2-bcb3-f8a2988b29de/0daa3fe1-0419-4a8e-a959-f890c2c1c857?org_if_sml=1&amp;func=proxy&amp;force_format=original"
        />
      </Helmet>
      <div className="accueuil-navbar">
        <header data-thq="thq-navbar" className="accueuil-navbar-interactive">
          <div className="accueuil-container01">
            <div className="accueuil-container02">
              <img
                alt="pastedImage"
                src="/external/pastedimage-vfn9-200h.png"
                className="accueuil-pasted-image"
              />
              <div className="accueuil-branding">
                <span className="accueuil-text">
                  <span className="accueuil-text001">
                    Sophrologie et Gestalt-thérapie
                  </span>
                  <br></br>
                </span>
                <span className="accueuil-text003">Sandrine PERRIERE</span>
              </div>
            </div>
          </div>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="accueuil-desktop-menu"
          >
            <nav className="accueuil-links">
              <button className="accueuil-button button">
                <a href="#accomp" className="accueuil-link">
                  Mes accompagnements
                </a>
              </button>
              <button className="accueuil-button1 button">
                <a href="#coord" className="accueuil-link01">
                  Prendre rendez-vous
                </a>
              </button>
              <button className="accueuil-button2 button">Menu</button>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="accueuil-burger-menu">
            <img
              alt="image"
              src="/group%202084%5B1%5D.svg"
              className="accueuil-hamburger"
            />
          </div>
          <div data-thq="thq-mobile-menu" className="accueuil-mobile-menu">
            <div className="accueuil-top">
              <div className="accueuil-branding1">
                <span className="accueuil-text004">test menu</span>
                <svg viewBox="0 0 1024 1024" className="accueuil-icon">
                  <path d="M128 128h768v768h-768z"></path>
                </svg>
                <span className="accueuil-text005">menu</span>
              </div>
              <div data-thq="thq-close-menu" className="accueuil-menu-close">
                <svg viewBox="0 0 1024 1024" className="accueuil-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="accueuil-links1">
              <button className="accueuil-button3 button">
                <span>Our offers</span>
              </button>
              <button className="accueuil-button4 button">
                <span>Get a fast quote</span>
              </button>
              <button className="accueuil-button5 button">Menu</button>
            </nav>
            <div className="accueuil-social-bar">
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="accueuil-icon04"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="accueuil-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="accueuil-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="accueuil-icon10"
              >
                <path d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
              <svg viewBox="0 0 1024 1024" className="accueuil-icon12">
                <path d="M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z"></path>
              </svg>
            </div>
          </div>
        </header>
      </div>
      <header className="accueuil-hero">
        <div className="accueuil-container03">
          <div className="accueuil-content">
            <h1 className="accueuil-title">Bienvenue dans mon univers !</h1>
            <span className="accueuil-description">
              Je suis Sandrine Perriere Sophrologue et Gestalt-praticienne, j’ai
              à cœur de vous accompagner pour que vous deveniez acteur de votre
              bien-être et de vos prises de conscience
            </span>
            <img alt="image" src="/line-300w.png" className="accueuil-image" />
          </div>
          <div className="accueuil-social-bar1">
            <a
              href="https://www.facebook.com/profile.php?id=100069016761446"
              target="_blank"
              rel="noreferrer noopener"
              className="accueuil-link02"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="accueuil-icon14"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
            <a
              href="https://www.psychologue.net/cabinets/sandrine-perriere"
              target="_blank"
              rel="noreferrer noopener"
              className="accueuil-link03"
            >
              <img
                alt="image"
                src="/icon-512x512-200h.png"
                className="accueuil-image01"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/sandrine-perriere-rouxel-6a723114b"
              target="_blank"
              rel="noreferrer noopener"
              className="accueuil-link04"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="accueuil-icon16"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="accueuil-image02">
          <img
            alt="image"
            src="/sophrotete-500h.jpg"
            className="accueuil-image03"
          />
          <img
            alt="image"
            src="/sophrologie-700h.jpg"
            className="accueuil-image04"
          />
        </div>
      </header>
      <section className="accueuil-mission">
        <h2 className="accueuil-text008">
          Offrez vous un temps pour vous dans un cadre chaleureux, un espace de
          confidentialité
        </h2>
        <a href="#seance" autoFocus className="accueuil-link05 button">
          <span className="accueuil-text009">
            <span className="accueuil-text010">En savoir plus</span>
            <br></br>
          </span>
        </a>
      </section>
      <animate-on-reveal
        animation="fadeIn"
        duration="2000ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
      >
        <div
          id="qui suis je"
          data-thq-animate-on-reveal="true"
          className="accueuil-features"
        >
          <h1 className="accueuil-text012">Qui suis-je ?</h1>
          <div className="accueuil-separator"></div>
          <div className="accueuil-container04">
            <div className="accueuil-container05">
              <span className="accueuil-text013">
                <span>
                  Une palette de couleur, une palette d&apos; émotions , une
                  palette d&apos; expériences.
                </span>
                <br></br>
                <br></br>
                <span>
                  C &apos;est ce que j&apos; ai expérimenté en travaillant sur
                  moi alors qu &apos;avant c&apos; était tout noir ou tout blanc
                  .
                </span>
                <br></br>
                <br></br>
                <span>
                  Pendant plusieurs années en tant que conseillère
                  commerciale:je suis allée à la découverte des attentes et des
                  besoins de mes clients,
                </span>
                <br></br>
                <br></br>
                <span>
                  Puis j&apos; ai encadré des commerciaux pour un grand groupe
                  mutualiste:j’ai développé mes capacités à gérer les
                  conflits,et à gérer le stress des collaborateurs.
                </span>
                <br></br>
                <br></br>
                <span>
                  Dans l’accompagnement des personnes qui me faisaient confiance
                  je me suis rendu compte qu’on me déposait des choses au-delà
                  du professionnel et que je n’avais pas d’outils pour faire
                  avec.
                </span>
                <br></br>
                <br></br>
                <span>
                  {' '}
                  Donc c’est là que j’ai décidé de me former : au départ juste
                  pour avoir des clés.
                </span>
                <br></br>
                <br></br>
                <span>
                  Après avoir traversé l’épreuve du deuil de ma maman, et un
                  burn out; j&apos;ai redécouvert mes ressources, je me suis
                  réapproprié mon corps et je me suis enfin écoutée.
                </span>
                <br></br>
                <br></br>
                <span>
                  Totalement alignée avec mes vraies aspirations et j’ai décidé
                  de vivre pleinement de ces deux métiers qui me nourrissent et
                  me portent tous les jours.
                </span>
                <br></br>
                <br></br>
                <span>
                  J’ai à cœur de vous apporter le soutien, l’écoute, et la
                  bienveillance pour vous aider à vous reconnecter à votre corps
                  avec vos ressources, vos sensations et améliorer votre état de
                  mieux être.
                </span>
                <br></br>
                <br></br>
                <span>
                  Vous aidez à faire un pas de côté, faire un avec, comme ce que
                  j’expérimente aussi en pratiquant l’aïkido.
                </span>
                <br></br>
                <br></br>
              </span>
            </div>
            <img alt="image" src="/pp2-500w.png" className="accueuil-image05" />
          </div>
        </div>
      </animate-on-reveal>
      <section className="accueuil-partners">
        <div className="accueuil-content1">
          <h2 className="accueuil-text044">Mes formations</h2>
        </div>
        <div className="accueuil-partners-mobile">
          <div className="accueuil-row"></div>
          <div className="accueuil-column"></div>
          <div className="accueuil-column01"></div>
          <div className="accueuil-column02"></div>
          <img
            alt="image"
            src="/logoaliota-200h.jpeg"
            className="accueuil-image06 partner-image"
          />
          <img
            alt="image"
            src="/gestaltlogo-1400w.png"
            className="accueuil-image07 partner-image"
          />
        </div>
        <div className="accueuil-partners-desktop">
          <div className="accueuil-column03">
            <div className="partner-container">
              <img
                alt="image"
                src="/logoaliota-200h.jpeg"
                className="accueuil-image08 partner-image"
              />
            </div>
          </div>
          <div className="accueuil-column04">
            <div className="accueuil-partner1 partner-container">
              <img
                alt="image"
                src="/gestaltlogo-1400w.png"
                className="accueuil-image09 partner-image"
              />
            </div>
          </div>
          <div className="accueuil-column05"></div>
          <div className="accueuil-column06"></div>
        </div>
      </section>
      <section className="accueuil-improve">
        <div className="accueuil-heading">
          <h2 id="accomp" className="accueuil-text045">
            Mes accompagnements
          </h2>
          <p className="accueuil-text046">
            <br className="accueuil-text047"></br>
            <span className="accueuil-text048">
              Un accompagnement dans votre parcours de vie quel que soit l’étape
              du chemin.
            </span>
            <br className="accueuil-text049"></br>
            <span className="accueuil-text050">
              Il n&apos;y a pas d&apos;âge pour aller mieux et prendre soin de
              soi.
            </span>
            <br className="accueuil-text051"></br>
            <br className="accueuil-text052"></br>
            <span className="accueuil-text053">
              Le meilleur moment c&apos;est l&apos; instant : Ici et maintenant
            </span>
            <br className="accueuil-text054"></br>
            <br className="accueuil-text055"></br>
          </p>
        </div>
        <div className="accueuil-tabs">
          <div className="accueuil-switches">
            <span className="accueuil-switch switch switch-active">
              Adultes
            </span>
            <span className="accueuil-switch1 switch">Enfants et ados</span>
          </div>
          <div className="accueuil-content2">
            <div className="accueuil-details">
              <div className="accueuil-header">
                <h2 id="seance" className="accueuil-text056">
                  Séance adulte
                </h2>
                <div className="accueuil-description1">
                  <p className="accueuil-text057">
                    <span>
                      Je propose en sophrologie des séances individuelles ou en
                      groupe, en présentiel ou en ligne.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Après un temps de centrage je propose un travail de prise
                      de conscience du corps via des exercices simples de
                      décontractions, de respirations ainsi que des
                      visualisations positives.
                    </span>
                    <br className="accueuil-text062"></br>
                    <br></br>
                    <span>
                      En séance individuel nous allons formaliser ensemble un
                      objectif : à chaque séance nous travaillerons une
                      intention et vous expérimenterez des outils que vous
                      pourrez utiliser immédiatement dans votre quotidien.
                    </span>
                    <br className="accueuil-text065"></br>
                    <br></br>
                    <span>
                      Pour les personnes qui souhaitent mettre de la conscience
                      sur leur mode de fonctionnement, leurs croyances et leurs
                      valeurs : je propose un accompagnement en
                      Gestalt-thérapie. Un accompagnement davantage sur le long
                      terme où,ensemble, nous prendrons le temps de revisiter
                      tout ce qui vous active. Plus qu’une méthode, la
                      Gestalt-thérapie est une approche qui prend en compte
                      l’être humain dans toutes ses dimensions, corporelle,
                      émotionnelle, mentale, sociale et spirituelle.
                    </span>
                    <br></br>
                  </p>
                  <p className="accueuil-text069">
                    Elle s’adresse aux personnes ressentant un mal être dans
                    leur vie actuelle, mais également aux personnes ressentant
                    le besoin de donner du sens à leur vie ainsi qu’aux
                    personnes cherchant une réunification avec soi-même : un
                    alignement.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </p>
                </div>
              </div>
              <a href="#coord" className="accueuil-link06 button">
                <span className="accueuil-text070">
                  <span>Prendre rendez-vous</span>
                  <br></br>
                </span>
              </a>
            </div>
            <div className="accueuil-image10">
              <img
                alt="image"
                src="/cabinet-500h.jpg"
                className="accueuil-image11"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="accueuil-testimonial">
        <div className="accueuil-container06">
          <h1 className="accueuil-text073">
            <span>Ce qu&apos;ils en pensent</span>
            <br></br>
          </h1>
          <span className="accueuil-text076">
            Les recommandations de mes clients depuis la plateforme
            psychologue.net
          </span>
          <div className="accueuil-container07"></div>
          <div className="accueuil-testimonial-card">
            <div className="accueuil-profile">
              <img
                alt="John Doe"
                src="/3702698-200h.png"
                className="accueuil-image12"
              />
              <div className="accueuil-container08">
                <span className="accueuil-text077">Fanfan</span>
              </div>
            </div>
            <span className="accueuil-text078">
              Depuis plusieurs mois je suis suivie par Sandrine .Pour la
              première fois de ma vie , je me sens écoutée, comprise et je sens
              déjà la différence sur ma capacité à prendre du recul , reprendre
              confiance et comprendre enfin qui je suis. Sandrine a une écoute
              hors du commun , nous mettant très à l&apos;aise avec
              bienveillance et empathie. et beaucoup de professionnalisme
              .J&apos;appréhendais le fait que ce soit en visio mais en fait
              tout est facile et simple . Je remercie Sandrine et je la
              recommande les yeux fermés !
            </span>
            <svg viewBox="0 0 1024 1024" className="accueuil-icon18">
              <path d="M225 448c123.712 0 224 100.29 224 224 0 123.712-100.288 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.634 11.636-22.252 24.016-31.83 37.020 11.438-1.8 23.16-2.746 35.104-2.746zM801 448c123.71 0 224 100.29 224 224 0 123.712-100.29 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.636 11.636-22.254 24.016-31.832 37.020 11.44-1.8 23.16-2.746 35.106-2.746z"></path>
            </svg>
          </div>
          <div className="accueuil-testimonial-card1">
            <div className="accueuil-profile1">
              <img
                alt="John Doe"
                src="/3702698-200h.png"
                className="accueuil-image13"
              />
              <div className="accueuil-container09">
                <span className="accueuil-text079">Frédérique</span>
              </div>
            </div>
            <span className="accueuil-text080">
              A chaque rencontre avec Sandrine, c&apos;est une invitation pour
              faire une pause dans son quotidien, prendre du recul et observer
              ce qu&apos;il se passe, sans jugement. Je peux affirmer en toute
              sincérité que notre travail m&apos;a apporté de grands bienfaits.
              C&apos;est un guide lumineux et bienveillant pour cheminer vers
              nos zones d&apos;ombres, afin de mieux les accepter, et peut-être,
              de les sublimer. Gratitude Sandrine !
            </span>
            <svg viewBox="0 0 1024 1024" className="accueuil-icon20">
              <path d="M225 448c123.712 0 224 100.29 224 224 0 123.712-100.288 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.634 11.636-22.252 24.016-31.83 37.020 11.438-1.8 23.16-2.746 35.104-2.746zM801 448c123.71 0 224 100.29 224 224 0 123.712-100.29 224-224 224s-224-100.288-224-224l-1-32c0-247.424 200.576-448 448-448v128c-85.474 0-165.834 33.286-226.274 93.726-11.636 11.636-22.254 24.016-31.832 37.020 11.44-1.8 23.16-2.746 35.106-2.746z"></path>
            </svg>
          </div>
          <div className="accueuil-testimonial-card2">
            <div className="accueuil-profile2">
              <img
                alt="John Doe"
                src="/3702698-200h.png"
                className="accueuil-image14"
              />
              <div className="accueuil-container10">
                <span className="accueuil-text081">Christelle</span>
              </div>
            </div>
            <span className="accueuil-text082">
              <span>
                Sandrine m a accompagné avec professionnalisme à développer mon
                potentiel et à y voir plus clair. Je me suis sentie soutenue et
                accueillie avec bienveillance.
              </span>
              <br></br>
              <span>
                J ai pu retrouver de la confiance pour résoudre mes difficultés.
              </span>
              <br></br>
              <br></br>
              <span>Merci infiniment Sandrine !</span>
            </span>
          </div>
        </div>
      </div>
      <section className="accueuil-services">
        <h2 className="accueuil-text089">
          Une thérapie orientée changement ou acceptation
        </h2>
        <div className="accueuil-cards">
          <div className="accueuil-row1">
            <Card
              title="Une aide  pour retrouver du sens"
              rootClassName="card-root-class-name1"
            ></Card>
            <Card
              icon="/group%201644.svg"
              title="Une aide pour regarder ensemble vos blocages"
              rootClassName="card-root-class-name"
            ></Card>
          </div>
          <div className="accueuil-row2">
            <Card
              icon="/group%201645.svg"
              title="Une aide pour découvrir et vous aligner à vos valeurs."
              rootClassName="card-root-class-name2"
            ></Card>
            <Card
              icon="/group%201646.svg"
              title="Une aide pour augmenter  sa confiance en soi"
              rootClassName="card-root-class-name3"
            ></Card>
          </div>
        </div>
      </section>
      <section className="accueuil-testimonials">
        <div>
          <div className="accueuil-container12">
            <Script
              html={`<script>
  /*
              Quote Slider - Code Embed
              */

              let current = 1;

              const nextButton = document.querySelector("#quote-next");
              const previousButton = document.querySelector("#quote-previous");
              const quotes = document.querySelectorAll(".quote");

              if(nextButton&&previousButton){
                nextButton.addEventListener("click", () => {
                quotes.forEach((quote) => {
                quote.classList.remove("active-quote");
                });

                current == quotes.length ? current = 1 : current++
                quotes[current-1].classList.add("active-quote")
                });

                previousButton.addEventListener("click", () => {
                quotes.forEach((quote) => {
                quote.classList.remove("active-quote");
                });

                current == 1 ? current = quotes.length : current--
                quotes[current-1].classList.add("active-quote")
                });

              }
</script>
`}
            ></Script>
          </div>
        </div>
      </section>
      <div id="sophro" className="accueuil-hero1">
        <div className="accueuil-container13">
          <h1 className="accueuil-text090">
            <span>
              Qu&apos;est-ce que la
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="accueuil-text092">sophrologie</span>
            <span> pour moi?</span>
          </h1>
          <span className="accueuil-text094">
            <span className="accueuil-text095">
              C&apos;est une méthode psycho corporelle qui permet d améliorer
              son bien-être au quotidien ou de développer son potentiel et même
              d améliorer sa condition en cas de maladie.
            </span>
            <br className="accueuil-text096"></br>
            <br className="accueuil-text097"></br>
            <span className="accueuil-text098">
              Une méthode qui permet à mes clients de devenir acteur de leur
              mieux être,
            </span>
            <br className="accueuil-text099"></br>
            <br className="accueuil-text100"></br>
            <span className="accueuil-text101">
              Revenir au corps et aux sensations, y mettre de la conscience et
              des mots: c&apos;est pour moi une des clés pour retrouver le
              chemin de ses ressources intérieures.
            </span>
            <br className="accueuil-text102"></br>
            <br className="accueuil-text103"></br>
            <span className="accueuil-text104">
              Se reconnecter à ses ressentis profonds que l &apos;on occulte
              souvent en étant toujours de le faire
            </span>
            <br className="accueuil-text105"></br>
            <br></br>
          </span>
        </div>
        <animate-on-reveal
          animation="bounce"
          duration="1s"
          delay="0s"
          direction="normal"
          easing="ease"
          iteration="1"
        >
          <img
            alt="image"
            src="/undraw_mindfulness_8gqa%20(1).svg"
            data-thq-animate-on-reveal="true"
            className="accueuil-image15"
          />
        </animate-on-reveal>
      </div>
      <section className="accueuil-banner"></section>
      <section className="accueuil-mission1">
        <div className="accueuil-content3">
          <div className="accueuil-description2">
            <h1 className="accueuil-text107">
              <span>Le but d&apos; une séance</span>
              <br></br>
            </h1>
            <p className="accueuil-text110">
              <span className="accueuil-text111">
                Apprendre à se détendre et à prendre de la distance avec ses
                émotions négatives et à se concentrer sur ses ressentis s
                &apos;offrir un cadeau une rencontre avec soi
              </span>
              <br className="accueuil-text112"></br>
              <br className="accueuil-text113"></br>
              <br className="accueuil-text114"></br>
              <br className="accueuil-text115"></br>
            </p>
            <h1 className="accueuil-text116">
              <span>Comment ça fonctionne ?</span>
              <br></br>
            </h1>
            <span className="accueuil-text119">Grâce à trois procédés : </span>
            <div className="accueuil-features1">
              <div className="accueuil-container14">
                <FeatureCard3
                  title="La respiration contrôlée"
                  rootClassName="rootClassName"
                ></FeatureCard3>
                <FeatureCard3
                  title=" La détente musculaire"
                  rootClassName="rootClassName3"
                ></FeatureCard3>
                <FeatureCard3
                  title="La suggestion mentale"
                  rootClassName="rootClassName5"
                ></FeatureCard3>
              </div>
            </div>
            <h1 className="accueuil-text120">
              <br></br>
              <span>Comment se passe une première séance ?</span>
            </h1>
            <p className="accueuil-text123">
              <span>
                Un moment d&apos;échanges ou l&apos; on apprend à se connaître
                dans un cadre sans jugement ou nous définirons ensemble votre
                objectif.
              </span>
              <br></br>
              <br></br>
              <span>
                La découverte de relaxation dynamique: des mouvements doux
                associés à la respiration et à une intention: des mouvements
                simples que vous pourrez refaire facilement,
              </span>
              <br></br>
              <br></br>
              <span>
                Puis la découverte de la sophronisation: relaxation statique
                avec des visualisations positives: que vous pourrez vivre soit
                assis soit allongé une prise de conscience du corps a travers
                les sensations, les inductions et les ancrages.
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="accueuil-galleries">
        <div className="accueuil-gallery-desktop">
          <div className="accueuil-column07">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1541976844346-f18aeac57b06?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEwfHx0aGVyYXB5fGVufDB8fHx8MTY3NDI0MzgxNw&amp;ixlib=rb-4.0.3&amp;w=1300"
              className="accueuil-image16"
            />
          </div>
          <div className="accueuil-column08">
            <div className="accueuil-row3">
              <div className="accueuil-yellow"></div>
              <img
                alt="image"
                src="/porte-1300w.jpg"
                className="accueuil-image17"
              />
            </div>
            <div className="accueuil-row4">
              <img
                alt="image"
                src="/pexels-darina-belonogova-7964869%201-500w.png"
                className="accueuil-image18"
              />
              <img
                alt="image"
                src="/arbevie-400h.webp"
                className="accueuil-image19"
              />
            </div>
          </div>
        </div>
        <div className="accueuil-gallery-mobile">
          <div className="accueuil-column09">
            <img
              alt="image"
              src="/1676643205312-700h.png"
              className="accueuil-image20"
            />
            <img
              alt="image"
              src="/pexels-darina-belonogova-7964869%201-500w.png"
              className="accueuil-image21"
            />
          </div>
          <div className="accueuil-column10">
            <img
              alt="image"
              src="/cabinet-500h.jpg"
              className="accueuil-image22"
            />
            <div className="accueuil-row5">
              <img
                alt="image"
                src="/arbevie-400h.webp"
                className="accueuil-image23"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="accueuil-mission2">
        <div id="gestalt" className="accueuil-hero2">
          <div className="accueuil-container15">
            <h1 className="accueuil-text131">
              <span>
                Qu&apos;est-ce que la
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="accueuil-text133">Gestalt-thérapie</span>
              <span> pour moi?</span>
            </h1>
            <span className="accueuil-text135">
              <br className="accueuil-text136"></br>
              <span className="accueuil-text137">
                Pour les personnes qui souhaitent mettre de la conscience sur
                leur mode de fonctionnement, leur croyances et leurs valeurs je
                propose un accompagnement en gestalt-thérapie.
              </span>
              <br className="accueuil-text138"></br>
              <br className="accueuil-text139"></br>
              <span className="accueuil-text140">
                {' '}
                Un accompagnement plus sur le long terme où ensemble on prend le
                temps de revisiter tout ce qui vous active.
              </span>
              <br className="accueuil-text141"></br>
              <br className="accueuil-text142"></br>
              <span className="accueuil-text143">
                Plus qu’une méthode, la Gestalt-thérapie est une approche qui
                prend en compte l’être humain dans toutes ses dimensions :
                corporelle, émotionnelle, mentale, sociale et spirituelle.
              </span>
              <br className="accueuil-text144"></br>
              <br className="accueuil-text145"></br>
              <span className="accueuil-text146">
                Elle s’adresse aussi bien aux personnes ressentant un mal être
                dans leur vie actuelle, qu’aux personnes ressentant le besoin de
                donner du sens à leur vie.
              </span>
              <br className="accueuil-text147"></br>
              <br className="accueuil-text148"></br>
              <br></br>
            </span>
          </div>
          <animate-on-reveal
            animation="zoomIn"
            duration="3000ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/undraw_heartbroken_cble.svg"
              data-thq-animate-on-reveal="true"
              className="accueuil-image24"
            />
          </animate-on-reveal>
        </div>
      </section>
      <div className="accueuil-container16">
        <h1 className="accueuil-text150">L’objectif ?</h1>
      </div>
      <span className="accueuil-text151">
        <span>Une réunification avec soi : un alignement</span>
        <br></br>
        <br></br>
      </span>
      <section className="accueuil-newsletter">
        <div className="accueuil-header1">
          <h2 className="accueuil-text155">Horaires du cabinet</h2>
          <p className="accueuil-text156">
            Séances possibles également à domicile ou en visioconférence.
          </p>
        </div>
        <div className="accueuil-container17">
          <animate-on-reveal
            animation="swing"
            duration="800ms"
            delay="0s"
            direction="normal"
            easing="ease"
            iteration="1"
          >
            <img
              alt="image"
              src="/undraw_time_management_re_tk5w%20(1).svg"
              data-thq-animate-on-reveal="true"
              className="accueuil-image25"
            />
          </animate-on-reveal>
          <img
            alt="image"
            src="/horaire%20(2)-600w.png"
            className="accueuil-image26"
          />
        </div>
      </section>
      <div className="accueuil-pricing">
        <div className="accueuil-container18">
          <div id="coord" className="accueuil-container19">
            <div className="accueuil-container20">
              <h1 className="accueuil-text157">Coordonnées du cabinet</h1>
              <span className="accueuil-text158">
                Prise de rendez-vous en ligne ou par téléphone 
              </span>
            </div>
            <animate-on-reveal
              animation="jackInTheBox"
              duration="5000ms"
              delay="0s"
              direction="normal"
              easing="linear"
              iteration="10"
            >
              <img
                alt="image"
                src="/undraw_contact_us_re_4qqt.svg"
                data-thq-animate-on-reveal="true"
                className="accueuil-image27"
              />
            </animate-on-reveal>
          </div>
          <div className="accueuil-container21">
            <div className="accueuil-container22">
              <span className="accueuil-text159">Sandrine PERRIERE</span>
              <span className="accueuil-text160">
                <span> 108 rue Jean Jaurès</span>
                <br></br>
                <span> 54550 Pont Saint Vincent</span>
                <br></br>
              </span>
              <span className="accueuil-text165">Tel  : 06 56 69 03 13</span>
              <a href="tel:+33656690313" className="accueuil-link07 button">
                Téléphoner
              </a>
              <a
                href="https://www.psychologue.net/cabinets/sandrine-perriere"
                target="_blank"
                rel="noreferrer noopener"
                className="accueuil-link08 button"
              >
                Prendre rendez-vous
              </a>
              <div className="accueuil-social-bar2">
                <a
                  href="https://www.facebook.com/profile.php?id=100069016761446"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="accueuil-link09"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="accueuil-icon22"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.psychologue.net/cabinets/sandrine-perriere"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="accueuil-link10"
                >
                  <img
                    alt="image"
                    src="/icon-512x512-200h.png"
                    className="accueuil-image28"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/sandrine-perriere-rouxel-6a723114b"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="accueuil-link11"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="accueuil-icon24"
                  >
                    <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accueuil-container23">
        <div className="accueuil-container24">
          <div className="accueuil-container25">
            <div className="accueuil-container26"></div>
          </div>
        </div>
      </div>
      <div className="accueuil-container27">
        <div className="accueuil-container28">
          <Script
            html={`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2638.478414038766!2d6.101016976003812!3d48.600685018808065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4794a1abae97f67b%3A0x7efb3746830c3570!2s108%20Rue%20Jean%20Jaur%C3%A8s%2C%2054550%20Pont-Saint-Vincent!5e0!3m2!1sen!2sfr!4v1696591982253!5m2!1sen!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}
          ></Script>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
      <div>
        <div className="accueuil-container30">
          <Script
            html={`<style>
    .partner-container:hover .partner-image{
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(101%) contrast(102%);
    }
</style>`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Accueuil
