import React from 'react'

import PropTypes from 'prop-types'

import Branding from './branding'
import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-information">
        <div className="footer-content">
          <div className="footer-header">
            <Branding className=""></Branding>
            <p className="footer-text">{props.text2}</p>
          </div>
          <div className="footer-contact-list">
            <div className="footer-contact">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="footer-image"
              />
              <a
                href="mailto:sandrine.perriere0057@orange.fr?subject="
                className="footer-link"
              >
                {props.text11}
              </a>
            </div>
            <div className="footer-contact1">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="footer-image1"
              />
              <a href="tel:0656690313" className="footer-link1">
                {props.text22}
              </a>
            </div>
          </div>
        </div>
        <a
          href="https://www.linkedin.com/in/l%C3%A9o-perriere-472986197/"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-copyright"
        >
          {props.copyright}
        </a>
      </div>
      <div className="footer-links">
        <div className="footer-column">
          <a href="#qui suis je" className="footer-text1 link">
            {props.text}
          </a>
          <a href="#accomp" className="footer-text2 link">
            {props.text1}
          </a>
          <a href="#sophro" className="footer-text3 link">
            {props.text21}
          </a>
          <a href="#gestalt" className="footer-text4 link">
            {props.text3}
          </a>
          <a href="#coord" className="footer-text5 link">
            {props.text5}
          </a>
        </div>
        <div className="footer-column1">
          <a
            href="https://www.linkedin.com/in/sandrine-perriere-rouxel-6a723114b"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-text6 link"
          >
            {props.text6}
          </a>
          <a
            href="https://www.psychologue.net/cabinets/sandrine-perriere"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-text7 link"
          >
            {props.text8}
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100069016761446"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-text8 link"
          >
            {props.text9}
          </a>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  text5: 'Coordonnées',
  text9: 'Facebook',
  text1: 'Accompagnements',
  copyright: '©2023 LP',
  imageAlt: 'image',
  text: 'Qui suis-je',
  text2:
    "Il n'y a pas d' age pour aller mieux... et pas de bon moment …. Il y a juste le moment présent...",
  imageSrc: '/group%201639.svg',
  text3: 'La Gestalt-Thérapie',
  text11: 'sandrine.perriere0057@orange.fr',
  text8: 'Psychologue.Net',
  text4: 'Blog',
  text6: 'Linkedin',
  text21: 'La Sophrologie',
  text22: '06 56 69 03 13',
  imageAlt1: 'image',
  imageSrc1: '/group%201640.svg',
  text7: 'Instagram',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  text5: PropTypes.string,
  text9: PropTypes.string,
  text1: PropTypes.string,
  copyright: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  imageSrc: PropTypes.string,
  text3: PropTypes.string,
  text11: PropTypes.string,
  text8: PropTypes.string,
  text4: PropTypes.string,
  text6: PropTypes.string,
  text21: PropTypes.string,
  text22: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc1: PropTypes.string,
  text7: PropTypes.string,
}

export default Footer
